<template>
  <div class="mainer" @click="close">
    <div class="box">
      <div class="title">
        <!-- {{ type == 1 ? '服务协议' : '平台隐私政策' }} -->
        {{ title }}
      </div>
      <div class="content" v-html="content">
        <!-- 本应用尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本应用会按照本隐私权政策的规定使用和披露您的个人信息。但本应用将以高度的勤勉、 1. 适用范围 (a)
        在您注册本应用帐号时，您根据本应用要求提供的个人注册信息； (b)
        在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和
        时间、软硬件特征信息及您需求的网页记录等数据； (c) 本应用通过合法途径从商业伙伴处取得的用户个人数据。 您了解并同意，以下信息不适用本隐私权政策： (a)
        您在使用本应用平台提供的搜索服务时输入的关键字信息； (b) 本应用收集到的您在本应用发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情； (c)
        违反法律规定或违反本应用规则行为及本应用已对您采取的措施。
        本应用尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本应用会按照本隐私权政策的规定使用和披露您的个人信息。但本应用将以高度的勤勉、 1. 适用范围 (a)
        在您注册本应用帐号时，您根据本应用要求提供的个人注册信息； (b)
        在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和
        时间、软硬件特征信息及您需求的网页记录等数据； (c) 本应用通过合法途径从商业伙伴处取得的用户个人数据。 您了解并同意，以下信息不适用本隐私权政策： (a)
        您在使用本应用平台提供的搜索服务时输入的关键字信息； (b) 本应用收集到的您在本应用发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情； (c)
        违反法律规定或违反本应用规则行为及本应用已对您采取的措施。 -->
      </div>
      <div class="btn">
        <el-button type="primary" @click="close">我知道了</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getConfigcontent } from '@/api/index'
export default {
  props: {
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      ruleForm: {},
      content: '',
      title: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    done() {},
    close() {
      if (this.type === 1) {
        this.$store.commit('hideAgreement')
      } else {
        this.$store.commit('hidePolicy')
      }
    },
    async getData() {
      const res = await getConfigcontent({
        parentType: 2,
        type: this.type === 1 ? 6 : 5,
      })
      if (res.code !== 0 || !res.config) return
      this.content = res.config.content
      this.title = res.config.title
    },
  },
}
</script>
<style lang="scss" scoped>
.mainer {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  background: rgba($color: #000000, $alpha: 0.3);
  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 729px;
    background: #ffffff;
    box-sizing: border-box;
    padding: 20px 46px 54px 46px;
    .content {
      margin-top: 65px;
      height: 495px;
      overflow: auto;
      line-height: 30px;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .btn {
    text-align: center;
    margin-top: 35px;
  }
}
</style>
