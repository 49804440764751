import axios from 'axios'
// import Vue from 'vue'
import AppStore from '@/data'
import { Message } from 'element-ui'
// import data from '@/data/index'
// axios.defaults.timeout = 10000
// axios.defaults.withCredentials = false // 跨域请求，允许保存cookie
// 添加请求拦截器
// var loading
import router from '@/router'

// export const post = (url, params = {}, config = {}) => {
//   return axios.post(url, params, config).then((res) => res)
// }
// 重写message提示 只提示一次
const showMessage = Symbol('showMessage')
class DonMessage {
  success(options, single = true) {
    this[showMessage]('success', options, single)
  }
  warning(options, single = true) {
    this[showMessage]('warning', options, single)
  }
  info(options, single = true) {
    this[showMessage]('info', options, single)
  }
  error(options, single = true) {
    this[showMessage]('error', options, single)
  }

  [showMessage](type, options, single) {
    if (single) {
      // 判断是否已存在Message
      if (document.getElementsByClassName('el-message').length === 0) {
        Message[type](options)
      }
    } else {
      Message[type](options)
    }
  }
}
var MessageOnce = new DonMessage()
const axiosConf = {
  // 统一的请求配置
  options: {
    timeout: 60000,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    withCredentials: true,
  },

  // 请求前处理
  beforeRequest(config) {
    config.headers = config.headers || {}
    if (AppStore.localState.info) {
      config.headers.token = AppStore.localState.info.token
    }
    // loading = Vue.prototype.$loading({
    //   lock: false,
    //   text: 'Loading',
    //   spinner: 'el-icon-loading',
    //   background: 'rgba(0, 0, 0, 0.7)',
    // })
    return config
  },

  // 请求后处理
  afterResponse(response) {
    // loading.close()
    if (response.data.code !== 0) {
      MessageOnce.error(response.data.msg)
      if (response.data.code === 401) {
        AppStore.localState.info = null
        router.push({ name: 'login' })
      }
      return Promise.reject(response)
    } else {
      return response
    }
  },

  // 为了将所有结果统一处理，http请求不抛出异常，只用Code返回异常状态。
  catchError() {
    return router.push({ name: '404' })
  },
}
// const baseURL = process.env.VUE_APP_NODE_ENV === 'development' ? '/property-api' : process.env.VUE_APP_BASE_URL + '/property-api'
// const baseURL = '/asset-platform-api'
function hrtProxy(baseURL) {
  const proxy = axios.create(Object.assign({ baseURL }, axiosConf.options))
  proxy.interceptors.request.use(axiosConf.beforeRequest, axiosConf.catchError) // req处理
  proxy.interceptors.response.use(axiosConf.afterResponse, axiosConf.catchError) // res处理
  return proxy
}
export default hrtProxy
