<template>
  <el-select :value="value" placeholder="请选择" :disabled="disabled" @change="change" clearable>
    <el-option v-for="item in options" :key="item.id" :label="item.district" :value="item.id"> </el-option>
  </el-select>
</template>

<script>
import { getDistrict } from '@/api/index'
export default {
  name: 'hrt-area-select',
  componentName: 'HrtAreaSelect',
  data() {
    return {
      placeholder: '请选择',
      options: [],
      selectValue: [],
      IsShow: false,
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    parentValue: {
      type: String,
      default: '1',
    },
    // 接受外部v-model传入的值，必须使用value
    value: {
      type: [String, Number],
    },
    data: {
      type: Object,
      default: function() {
        return {
          defalutValue: null,
          filedName: '',
          filedType: 0,
          id: '',
          notNull: 0,
          propertyProjectId: '',
          sort: 0,
          value: '',
          showValue: '',
        }
      },
    },
  },
  watch: {
    parentValue() {
      this.$emit('input', '') // 父级value清空时，子级也要清空
      this.$emit('change', '')
      this.data.showValue = ''
      this.options = []
      this.initData()
    },
  },
  computed: {},
  mounted() {
    this.initData()
  },
  methods: {
    change(value) {
      this.$emit('input', value)
      this.$emit('change', value)
      this.options.forEach((item) => {
        if (item.id == value) this.data.showValue = item.district
      })
    },
    initData() {
      if (!this.parentValue) return
      getDistrict({
        pid: this.parentValue,
      }).then(({ data }) => {
        this.options = data
      })
      // this.$http({
      //   url: '/property/district/queryAll',
      //   method: 'get',
      //   params: {
      //     pid: this.parentValue,
      //   },
      // }).then(({ data }) => {
      //   this.options = data.list
      // })
    },
  },
}
</script>
