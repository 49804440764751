<template>
  <div class="loginBox">
    <span v-if="showClose" class="iconfont icon-guanbi1" @click="$store.commit('hideLoading')"></span>
    <div class="title">欢迎登录</div>
    <div class="tabBox">
      <el-radio-group v-model="tabPosition" @change="loginTypeChange">
        <el-radio-button label="1">验证码登录</el-radio-button>
        <el-radio-button label="2">账号登录</el-radio-button>
      </el-radio-group>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm">
      <template v-if="tabPosition === '1'">
        <el-form-item prop="mobile">
          <el-input v-model="ruleForm.mobile" prefix-icon="el-icon-mobile-phone" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <getCodeInput v-model="ruleForm.code" :mobile="ruleForm.mobile" :showIcon="true"></getCodeInput>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item prop="mobile">
          <el-input v-model="ruleForm.mobile" prefix-icon="el-icon-mobile-phone" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="ruleForm.password" prefix-icon="el-icon-lock" placeholder="请输入密码" show-password></el-input>
        </el-form-item>
        <el-form-item prop="captcha">
          <el-row :gutter="20">
            <el-col :span="16">
              <el-input v-model.trim="ruleForm.captcha" placeholder="验证码"> </el-input>
            </el-col>
            <el-col :span="8" class="login-captcha">
              <img :src="captchaPath" @click="getCaptcha()" alt="" style="height: 42px; width: 100%; border-radius: 4px" />
            </el-col>
          </el-row>
        </el-form-item>
      </template>
    </el-form>
    <div class="resBtn" @click="$store.state.regLoading = true">
      注册新用户
    </div>
    <div class="mark">登录即同意《 <span class="link" @click="show(1)">服务协议</span> 》及《 <span class="link" @click="show(2)">平台隐私政策</span> 》</div>
    <div class="loginBtn" @click="login">
      登录
    </div>
  </div>
</template>
<script>
import getCodeInput from '@/views/components/getCodeInput.vue'
import { loginByMobile, loginByPassword, getUnreadMessageCount } from '@/api/user'
import { Encrypt } from '@/utils/crypto'
import { getUUID } from '@/utils'
import { IsDebug } from '@/config'
export default {
  props: {
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  components: { getCodeInput },
  data() {
    return {
      tabPosition: '1',
      ruleForm: {
        mobile: '',
        password: '',
        code: '',
        captcha: '',
        uuid: '',
      },
      captchaPath: '',
      rules: {
        mobile: [
          {
            required: true,
            message: '请输入手机号码',
            // blur和change事件触发检验
            trigger: ['blur'],
          },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入11位数手机号码',
            trigger: ['blur'],
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            // blur和change事件触发检验
            trigger: ['blur'],
          },
        ],
        captcha: [
          {
            required: true,
            message: '请输入图形验证码',
            // blur和change事件触发检验
            trigger: ['blur'],
          },
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            // blur和change事件触发检验
            trigger: ['blur'],
          },
          {
            pattern: /^\d{1,4}$/,
            message: '请输入正确的短信验证码',
            trigger: ['blur'],
          },
        ],
      },
    }
  },
  methods: {
    loginTypeChange(val) {
      if (val == 2) this.getCaptcha()
    },
    login() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return
        let request = this.tabPosition === '1' ? loginByMobile : loginByPassword
        const res = await request({ ...this.ruleForm, password: Encrypt(this.ruleForm.password) })
        if (res.code !== 0) {
          if (this.tabPosition == 2) this.getCaptcha()
          return
        }
        this.$AppStore.SaveTokenInfo(res.data)
        let result = await getUnreadMessageCount()
        if (result.code === 0) {
          this.$store.commit('saveUnreadNum', result.data > 99 ? '99+' : result.data)
        }
        if (this.$route.name === 'login') return this.$router.push({ name: 'index' })
        this.$store.commit('hideLoading')
      })
    },
    show(type) {
      if (type === 1) {
        this.$store.commit('showAgreement')
      } else {
        this.$store.commit('showPolicy')
      }
    },
    // 获取验证码
    getCaptcha() {
      this.ruleForm.uuid = getUUID()
      let url = ''
      if (IsDebug) {
        url = 'http://192.168.1.108:8809/asset-platform-api/app/auth'
      } else {
        const origin = location.origin
        url = origin + '/asset-platform-api/app/auth'
      }
      this.captchaPath = url + `/captcha.jpg?uuid=${this.ruleForm.uuid}`
    },
  },
}
</script>
<style lang="scss" scoped>
.resBtn {
  text-align: right;
  color: #1c78ef;
  cursor: pointer;
}
.loginBox {
  position: absolute;
  top: 50%;
  right: 132px;
  transform: translateY(-50%);
  width: 420px;
  opacity: 1;
  border-radius: 10px;
  padding: 50px 0 100px 0;
  box-sizing: border-box;
  .iconfont {
    font-size: 30px;
    color: #1c78ef;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  .title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 52px;
  }
  .tabBox {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .serchBtn {
    background: #1c78ef;
    color: #fff;
    font-size: 18px;
  }
  /deep/ {
    .el-input {
      color: #333;
      font-size: 16px;
    }
    .el-input__icon {
      font-size: 24px;
      color: #999;
    }
    .el-input--prefix .el-input__inner {
      padding-left: 60px;
    }
    .el-radio-group {
      margin-bottom: 48px;
      background: #409eff;
      border-radius: 20px;
    }
    .el-radio-button .el-radio-button__inner {
      border-radius: 20px;
    }
    .el-radio-button__inner {
      color: #fff;
      background-color: #409eff;
      border-color: #409eff;
      width: 152px;
      text-align: center;
      font-weight: bold;
    }

    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      color: #409eff;
      background-color: #fff;
      border-color: #409eff;
    }
  }
  .mark {
    color: #666;
    font-size: 12px;
    margin-top: 46px;
    .link {
      color: #1c78ef;
      cursor: pointer;
    }
  }
  .loginBtn {
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    background: #1c78ef;
    box-shadow: 0px 10px 20px rgba(28, 120, 239, 0.2);
    border-radius: 4px;
    margin-top: 32px;
    cursor: pointer;
  }
  .loginBtn:hover {
    opacity: 0.8;
  }
}
</style>
