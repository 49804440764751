import Vue from 'vue'
import { DateFormat } from '@/core/time'
import { moneyYuanToWan } from '@/core/math2'

Vue.filter('DateFormat', function(value, fmt) {
  return DateFormat(value, fmt)
})
// 将单位为【元】的值转化为单位为【万】的值
Vue.filter('ToWan', function(value) {
  if (typeof value === 'string') value = parseFloat(value)
  if (typeof value !== 'number') return ''
  return moneyYuanToWan(value)
})
