<template>
  <div class="realNameModel" v-if="$store.state.realNameShow">
    <div class="realNameBox">
      <div class="bigTitle">实名认证</div>
      <div class="remark">
        为有效保障您的资金安全，提高订单处理效率，请填写与您身份证一致的信息，否则不会通过审核
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item prop="userName" label="真实姓名">
          <el-input v-model="ruleForm.userName" placeholder="请填写您本人的真实姓名" maxlength="7"></el-input>
        </el-form-item>
        <el-form-item prop="idNo" label="身份证号">
          <el-input v-model="ruleForm.idNo" placeholder="请填写您本人的身份证号"> </el-input>
        </el-form-item>
      </el-form>
      <div class="btnBox">
        <el-button @click="close()">取消</el-button>
        <el-button type="primary" @click="onSubmit">提交认证</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { checkIdNo } from '@/api/index'
export default {
  data() {
    return {
      ruleForm: {
        userName: '',
        idNo: '',
      },
      rules: {
        userName: [
          {
            required: true,
            message: '请输入不超过7个字，只能纯汉字',
            // blur和change事件触发检验
            trigger: ['blur'],
          },
          {
            pattern: /^(?:[\u4e00-\u9fa5·]{0,16})$/,
            message: '请输入不超过7个字，只能纯汉字',
            // blur和change事件触发检验
            trigger: ['blur'],
          },
        ],
        idNo: [
          {
            required: true,
            message: '请输入18个字符，最后位可大小写X“',
            // blur和change事件触发检验
            trigger: ['blur'],
          },
          {
            pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
            message: '请输入18个字符，最后位可大小写X“',
            trigger: ['blur'],
          },
        ],
      },
    }
  },
  methods: {
    onSubmit() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          const res = await checkIdNo(this.ruleForm)
          if (res.code !== 0) return
          this.$message({
            message: '认证成功',
            type: 'success',
            duration: 1500,
          })
          this.close()
          this.$AppStore.localState.info.user = res.data
        }
      })
    },
    close() {
      this.$store.commit('hideRealName')
    },
  },
}
</script>
<style lang="scss" scoped>
// 实名认证遮罩
.realNameModel {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba($color: #000000, $alpha: 0.39);
  z-index: 200;
  .realNameBox {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 443px;
    background: #ffffff;
    border-radius: 8px;
    .bigTitle {
      padding: 20px 0 25px 40px;
      font-weight: bold;
      font-size: 20px;
      border-bottom: 1px solid #eee;
      margin-bottom: 34px;
    }
    .remark {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 50px;
    }
  }
  .demo-ruleForm {
    padding: 0 150px;
  }
  .btnBox {
    text-align: center;
    margin-top: 80px;
  }
}
</style>
