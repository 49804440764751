// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/iconfont.css'
import '@/core/filters'
import HrtUpload from '@/components/hrt-input/hrt-upload.vue'
import ElDict from '@/components/el-dict/index.vue'
import ElSelectAsset from '@/components/el-selectAsset/index.vue'
import hrtAreaCascader from '@/components/hrt-input/hrt-area-cascader.vue'
import HrtCountryCascader from '@/components/hrt-input/hrt-country-cascader.vue'
import hrtAreaSelect from '@/components/hrt-input/hrt-area-select.vue'
import numberInput from '@/components/numberInput.vue'
import common from '@/utils/common.js'
import htmlToPdf from '@/core/pdf.js'
import '@/filters/index'
Vue.use(common)
Vue.use(htmlToPdf)
Vue.component('hrt-upload', HrtUpload)
Vue.component('el-dict', ElDict)
Vue.component('el-selectAsset', ElSelectAsset)
Vue.component('hrt-area-cascader', hrtAreaCascader)
Vue.component('hrt-country-cascader', HrtCountryCascader)
Vue.component('hrt-area-select', hrtAreaSelect)
Vue.component('number-input', numberInput)
import AppStore from '@/data/index'
Vue.prototype.$AppStore = AppStore
// import '@/utils/rem.js'
Vue.use(ElementUI)
Vue.config.productionTip = false

// vue 电子签名
//main.js中引入：
import vueEsign from 'vue-esign'
Vue.use(vueEsign)
import { getQueryAll, getAssetType } from '@/api/assets'
import { getDistrict } from '@/api/index'
import { getUerInfo, getUnreadMessageCount } from '@/api/user'
getQueryAll().then((res) => {
  AppStore.sessionState.query = res.data
})
getAssetType({ status: 1, sort: 'sort' }).then((res) => {
  AppStore.sessionState.typeList = res.list
})
if (!AppStore.sessionState.districts) {
  getDistrict().then((res) => {
    AppStore.sessionState.districts = res.data
  })
}
if (AppStore.localState.info) {
  getUerInfo().then((res) => {
    if (res.data) {
      AppStore.localState.info.user = res.data
    } else {
      AppStore.localState.info = null
    }
  })
  getUnreadMessageCount().then((res) => {
    store.commit('saveUnreadNum', res.data > 99 ? '99+' : res.data)
  })
}
// 其他类型要素id 求购的时候用
Vue.prototype.otherAssetTypeSignId = '992b5d3167280e643732c03e629cf047'
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
