<template>
  <div>
    <el-cascader clearable v-show="!IsRead" ref="cascader" v-loading="loading" :value="value" :disabled="disabled" :options="tree" placeholder="请选择" @change="change"></el-cascader>
    <div v-if="IsRead && showName">
      {{ showName }}
    </div>
  </div>
</template>
<script>
import { getDistrict } from '@/api/index'
export default {
  name: 'hrt-area-cascader',
  componentName: 'HrtAreaCascader',
  data() {
    return {
      tree: [],
      list: [],
      loading: false,
      showName: '',
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    IsRead: {
      type: Boolean,
      default: false,
    },
    // 接受外部v-model传入的值，必须使用value
    value: {
      type: Array,
    },
  },
  async mounted() {
    await this.getArea()
    this.$nextTick(() => {
      setTimeout(() => {
        this.getName()
      }, 100)
    })
  },
  methods: {
    async getArea() {
      const localTree = sessionStorage.getItem('cascader')
      if (localTree) {
        this.tree = JSON.parse(localTree)
        return
      }
      this.loading = true
      getDistrict().then((res) => {
        this.loading = false
        this.list = res.data.map((item) => {
          return {
            value: item.id,
            label: item.district,
            pid: item.pid,
          }
        })
        const tree = this.initTree(1)
        this.tree = tree
        sessionStorage.setItem('cascader', JSON.stringify(this.tree))
      })
    },
    initTree(pid) {
      // jsonArray 变量数据
      // 第一次以后：根据id去查询pid相同的（相同为子数据）
      // 第一次：查找所有pid为1的数据组成第一级
      const children = this.list.filter((item) => item.pid === pid)
      if (!children.length) return null
      // 第一次：循环pid为1数组
      return children.map((item) => ({
        ...item,
        children: this.initTree(item.value),
      }))
    },
    change(value) {
      this.getName()
      this.$emit('input', value)
      this.$emit('change', value)
    },
    getName() {
      const CheckedNodes = this.$refs.cascader.getCheckedNodes()
      this.showName = CheckedNodes[0] ? CheckedNodes[0].pathLabels.join('/') : ''
    },
  },
}
</script>
