export default {
  path: '/user',
  name: 'userIndex',
  redirect: 'personalData',
  component: () => import('@/views/user/index'),
  children: [
    {
      path: '/personalData',
      name: 'personalData',
      component: () => import('@/views/user/personalData'),
      meta: {
        requireLogin: true,
        routeName: 'personalData',
      },
    },
    {
      path: '/changePassword',
      name: 'changePassword',
      component: () => import('@/views/user/changePassword'),
      meta: {
        requireLogin: true,
        routeName: 'changePassword',
      },
    },
    {
      path: '/myProperty',
      name: 'myProperty',
      component: () => import('@/views/user/myProperty'),
      meta: {
        keepAlive: true,
        routeName: 'myProperty',
      },
    },
    {
      path: '/myFinancing',
      name: 'myFinancing',
      component: () => import('@/views/user/myFinancing'),
      meta: {
        requireLogin: true,
        routeName: 'myFinancing',
      },
    },
    {
      path: '/financingDetail',
      name: 'financingDetail',
      component: () => import('@/views/user/financingDetail'),
      meta: {
        requireLogin: true,
        routeName: 'myFinancing',
      },
    },
    {
      path: '/myProject',
      name: 'myProject',
      component: () => import('@/views/user/myProject'),
      meta: {
        requireLogin: true,
        routeName: 'myProject',
      },
    },
    {
      path: '/myApply',
      name: 'myApply',
      component: () => import('@/views/user/myApply'),
      meta: {
        requireLogin: true,
        routeName: 'myApply',
      },
    },
    {
      path: '/deposit',
      name: 'deposit',
      component: () => import('@/views/user/deposit'),
      meta: {
        requireLogin: true,
        routeName: 'myApply',
      },
    },
    {
      path: '/myBidding',
      name: 'myBidding',
      component: () => import('@/views/user/myBidding'),
      meta: {
        requireLogin: true,
        routeName: 'myBidding',
      },
    },
    {
      path: '/letterOfAcceptance',
      name: 'letterOfAcceptance',
      component: () => import('@/views/user/myContract'),
      meta: {
        requireLogin: true,
        routeName: 'letterOfAcceptance',
      },
    },
    {
      path: '/uploadContract',
      name: 'uploadContract',
      component: () => import('@/views/user/myContract'),
      meta: {
        keepAlive: true,
        requireLogin: true,
        routeName: 'uploadContract',
      },
    },
    {
      path: '/changeMobile',
      name: 'changeMobile',
      component: () => import('@/views/user/changeMobile'),
      meta: {
        requireLogin: true,
        routeName: 'personalData',
      },
    },
    {
      path: '/addProperty',
      name: 'addProperty',
      component: () => import('@/views/user/property-add-or-update'),
      meta: {
        requireLogin: true,
        routeName: 'myProperty',
      },
    },
    {
      path: '/addProject',
      name: 'addProject',
      component: () => import('@/views/user/project-add-or-update'),
      meta: {
        requireLogin: true,
        routeName: 'myProject',
      },
    },
    {
      path: '/addFinancing',
      name: 'addFinancing',
      component: () => import('@/views/user/financing-add-or-update'),
      meta: {
        requireLogin: true,
        routeName: 'myFinancing',
      },
    },
    {
      path: '/assetvalue',
      name: 'assetvalue',
      component: () => import('@/views/user/assetvalue-common'),
      meta: {
        requireLogin: true,
        routeName: 'myProperty',
      },
    },
    {
      path: '/myAsk',
      name: 'myAsk',
      component: () => import('@/views/user/myAsk'),
      meta: {
        keepAlive: true,
        routeName: 'myAsk',
      },
    },
    {
      path: '/addAsk',
      name: 'addAsk',
      component: () => import('@/views/user/ask-add-or-update'),
      meta: {
        requireLogin: true,
        routeName: 'myAsk',
      },
    },
    {
      path: '/addSupply',
      name: 'addSupply',
      component: () => import('@/views/user/supply-add-or-update'),
      meta: {
        requireLogin: true,
        routeName: 'myAsk',
      },
    },
    {
      path: '/mySupplyDetail',
      name: 'mySupplyDetail',
      component: () => import('@/views/user/mySupplyDetail'),
      meta: {
        requireLogin: true,
        routeName: 'myAsk',
      },
    },
    {
      path: '/myAssetment',
      name: 'myAssetment',
      component: () => import('@/views/user/myAssetment'),
      meta: {
        requireLogin: true,
        routeName: 'myAssetment',
      },
    },
    {
      path: '/myOrder',
      name: 'myOrder',
      component: () => import('@/views/user/myOrder'),
      meta: {
        requireLogin: true,
        routeName: 'myOrder',
      },
    },
    {
      path: '/messageHome',
      name: 'messageHome',
      component: () => import('@/views/user/messageHome'),
      meta: {
        requireLogin: true,
        routeName: 'messageHome',
      },
    },
  ],
}
