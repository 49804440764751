// 标准乘法
export function mul(...numbers) {
  let m = 0
  let n = 1
  numbers.forEach((arg) => {
    const str = arg.toString()
    const arr = str.split('.')
    // 有小数。
    if (arr.length === 2) {
      m += arr[1].length
    }

    // 取得整数结果
    n *= Number(str.replace('.', ''))
  })
  return n / Math.pow(10, m)
}

// 标准加法
export function add(...numbers) {
  if (numbers.length === 0) return 0
  if (numbers.length === 1) return numbers[0]
  const len = []
  numbers.forEach((arg) => {
    const str = arg.toString()
    const arr = str.split('.')
    if (arr.length === 2) {
      len.push(arr[1].length)
    } else {
      len.push(0)
    }
  })
  const m = Math.pow(10, Math.max(...len))
  let sum = 0
  numbers.forEach((arg) => {
    sum += mul(arg, m)
  })
  return sum / m
}

// 标准除法
export function div(...numbers) {
  try {
    if (numbers.length > 2) {
      const last = numbers.pop() || 0
      return div(div(...numbers), last)
    }
    let t1 = 0
    let t2 = 0
    const s1 = numbers[0].toString()
    const s2 = numbers[1].toString()
    try {
      t1 = s1.split('.')[1].length
    } catch (e) {
      t1 = 0
    }
    try {
      t2 = s2.split('.')[1].length
    } catch (e) {
      t2 = 0
    }
    return mul(Number(s1.replace('.', '')) / Number(s2.replace('.', '')), Math.pow(10, t2 - t1))
  } catch (e) {
    return NaN
  }
}

// 四舍五入。不会留下末尾的 0
export function toFixed(num, bit = 2) {
  const len = Math.pow(10, bit)
  return Math.round(num * len) / len
}
/**
 * 元 --> 万
 */
export function moneyYuanToWan(fen) {
  return div(Math.round(fen), 10000)
}

/**
 * 万 --> 元
 */
export function moneyWanToYuan(yuan) {
  return mul(yuan, 10000)
}
