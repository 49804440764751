import hrt from './axios'
let hrtProxy = hrt('/asset-platform-api')
// const base = process.env.VUE_APP_BASE_URL
// 获取轮播咨询列表
export const getCarouselList = (params) => {
  return hrtProxy.get(`/app/news/listPic`, { params }).then((res) => res.data)
}
// 获取新闻等列表列表
export const getInformationList = (params) => {
  return hrtProxy.get(`/app/news/list`, { params }).then((res) => res.data)
}
// 获取新闻等详情
export const getInformationDetail = (id) => {
  return hrtProxy.get(`/app/news/info/${id}`).then((res) => res.data)
}
// 获取新闻等详情
export const getSelectNewsIndexs = (params) => {
  return hrtProxy.get(`/app/news/selectNewsIndexs`, { params }).then((res) => res.data)
}
// 获取新闻分类
export const getNewsCategoryList = (parentId) => {
  return hrtProxy.get(`/app/newsCategory/list`, { params: { parentId } }).then((res) => res.data)
}
// 获取首页资讯
export const getNewsIndexList = (params) => {
  return hrtProxy.get(`/app/news/indexList`, { params }).then((res) => res.data)
}
// 获取项目
export const getProjectList = (params) => {
  return hrtProxy.get(`/app/projectsubject/list`, { params }).then((res) => res.data)
}
// 获取推荐项目
export const getProjectRandList = (params) => {
  return hrtProxy.get(`/app/projectsubject/randlist`, { params }).then((res) => res.data)
}
// // 获取要素
// export const getAssettypesign = (params) => {
//   return hrtProxy.get(`/app/assettypesign/queryAll`, { params }).then((res) => res.data)
// }
// 获取省市区 /property/district/queryAll
export const getDistrict = (params) => {
  return hrtProxy.get(`/app/district/queryAll`, { params }).then((res) => res.data)
}
// 获取省市区名字 app/district/info
export const getDistrictInfo = (params) => {
  return hrtProxy.get(`/app/district/info/${params.id}`).then((res) => res.data)
}

// 更新头像 /platform-api/app/auth/updateHeadImgUrl
export const updateHeadImgUrl = (data) => {
  return hrtProxy.post(`/app/auth/updateHeadImgUrl`, data).then((res) => res.data)
}

// 实名认证 /platform-api/app/auth/checkIdNo
export const checkIdNo = (params) => {
  return hrtProxy.post(`/app/auth/checkIdNo`, params).then((res) => res.data)
}
// 获取首页背景
export const getBgurl = () => {
  return hrtProxy.get(`/app/bgurl/list`).then((res) => res.data)
}
// 获取隐私协议
export const getConfigcontent = (params) => {
  return hrtProxy.get(`/app/configcontent/info`, { params }).then((res) => res.data)
}
// 首页搜索 /platform-api/app/index/search
export const getSearch = (params) => {
  return hrtProxy.get(`/app/index/search`, { params }).then((res) => res.data)
}
// 新增访问量 app/accesslog/save
export const addAccseelog = (params) => {
  return hrtProxy.post(`/app/accesslog/save`, params).then((res) => res.data)
}
// 获取访问量 和用户量 app/accesslog/getCountForAccessAndUser
export const getCountForAccessAndUser = (params) => {
  return hrtProxy.get(`/app/accesslog/getCountForAccessAndUser`, { params }).then((res) => res.data)
}
// 留言板 /common/messageboard/
export const saveMessageboard = (params) => {
  return hrtProxy.post(`/app/messageboard/save`, params).then((res) => res.data)
}
// 获取验证码图片 get /app/auth/slideCaptcha
export const getSlideCaptcha = (params) => {
  return hrtProxy.get(`/app/auth/slideCaptcha`, { params }).then((res) => res.data)
}
// 验证验证码图片 get /app/auth/slideCaptchaCheck
export const slideCaptchaCheck = (params) => {
  return hrtProxy.get(`/app/auth/slideCaptchaCheck/${params.id}/${params.movePercent}`).then((res) => res.data)
}
