import Vue from 'vue'
import Router from 'vue-router'
import user from './modules/user.js'
Vue.use(Router)
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: 'index',
      component: () => import('@/views/home/index'),
      children: [
        user,
        {
          path: '/',
          name: 'index',
          component: () => import('@/views/index/index'),
          meta: {
            routerName: 'index',
            keepAlive: true,
          },
        },
        {
          path: '/news',
          name: 'news',
          component: () => import('@/views/notice/index'),
          meta: {
            routerName: 'news',
          },
        },
        {
          path: '/policy',
          name: 'policy',
          component: () => import('@/views/notice/index'),
          meta: {
            routerName: 'policy',
            keepAlive: true,
          },
        },
        {
          path: '/notice',
          name: 'notice',
          component: () => import('@/views/notice/index'),
          meta: {
            routerName: 'notice',
            keepAlive: true,
          },
        },
        {
          path: '/tradeHall',
          name: 'tradeHall',
          component: () => import('@/views/tradeHall/index'),
          meta: {
            routerName: 'tradeHall',
            keepAlive: true,
          },
        },
        {
          path: '/tradeDetail',
          name: 'tradeDetail',
          component: () => import('@/views/tradeHall/detail'),
          meta: {
            routerName: 'tradeHall',
          },
        },
        {
          path: '/apply',
          name: 'apply',
          component: () => import('@/views/tradeHall/apply'),
          meta: {
            routerName: 'tradeHall',
          },
        },
        {
          path: '/applyForm',
          name: 'applyForm',
          component: () => import('@/views/tradeHall/applyForm'),
          meta: {
            routerName: 'tradeHall',
          },
        },
        {
          path: '/addContracts',
          name: 'addContracts',
          component: () => import('@/views/user/addContracts'),
          meta: {
            requireLogin: true,
            routerName: 'tradeHall',
          },
        },
        {
          path: '/finance',
          name: 'finance',
          component: () => import('@/views/finance/index'),
          meta: {
            routerName: 'finance',
          },
        },
        {
          path: '/financeList',
          name: 'financeList',
          component: () => import('@/views/finance/financeList'),
          meta: {
            routerName: 'financeList',
            keepAlive: true,
          },
        },
        {
          path: '/financeDetail',
          name: 'financeDetail',
          component: () => import('@/views/finance/financeDetail'),
          meta: {
            routerName: 'financeList',
          },
        },
        {
          path: '/tradingGuide',
          name: 'tradingGuide',
          component: () => import('@/views/notice/index'),
          meta: {
            routerName: 'tradingGuide',
            keepAlive: true,
          },
        },
        {
          path: '/dataDownload',
          name: 'dataDownload',
          component: () => import('@/views/notice/index'),
          meta: {
            routerName: 'dataDownload',
            keepAlive: true,
          },
        },
        {
          path: '/noticeDetail',
          name: 'noticeDetail',
          component: () => import('@/views/notice/detail'),
          meta: {
            routerName: 'news,policy,notice,tradingGuide,dataDownload',
          },
        },
        {
          path: '/noticeTradeDetail',
          name: 'noticeTradeDetail',
          component: () => import('@/views/notice/tradeDetail'),
          meta: {
            routerName: 'notice',
          },
        },
        {
          path: '/ownershipDetail',
          name: 'ownershipDetail',
          component: () => import('@/views/notice/ownershipDetail'),
          meta: {
            routerName: 'notice',
          },
        },
        {
          path: '/financialNoticeDetail',
          name: 'financialNoticeDetail',
          component: () => import('@/views/notice/financialNoticeDetail'),
          meta: {
            routerName: 'notice',
          },
        },
        {
          path: '/ask',
          name: 'ask',
          component: () => import('@/views/index/ask'),
          meta: {
            routerName: 'ask',
            keepAlive: true,
          },
        },
        {
          path: '/askDetail',
          name: 'askDetail',
          component: () => import('@/views/index/askDetail'),
          meta: {
            routerName: 'ask',
          },
        },
        {
          path: '/collectiveEconomy',
          name: 'collectiveEconomy',
          component: () => import('@/views/index/collectiveEconomy'),
          meta: {
            routerName: 'collectiveEconomy',
            keepAlive: true,
          },
        },
        {
          path: '/searchResult',
          name: 'searchResult',
          component: () => import('@/views/index/searchResult'),
          meta: {
            keepAlive: true,
            routerName: 'searchResult',
          },
        },
        {
          path: '/payResult',
          name: 'payResult',
          component: () => import('@/views/index/payResult'),
          meta: {
            routerName: 'ask',
          },
        },
        {
          path: '/myRoam',
          name: 'myRoam',
          component: () => import('@/views/roam/myRoam'),
          meta: {
            requireLogin: true,
            routeName: 'myRoam',
          },
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/index'),
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/home/404'),
    },
    {
      path: '/tradeCenter',
      name: 'tradeCenter',
      component: () => import('@/views/tradeCenter/index'),
    },
  ],
})
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  // document.documentElement.scrollTop = 0
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next()
})
// 路有报错刷新页面
router.onError((error) => {
  const pattern = /Loading chunk (\w)+ failed/g
  const isError = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isError) {
    router.replace(targetPath)
  }
})
export default router
