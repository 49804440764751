import AppStore from '@/data/index'
export default function(Vue) {
  Vue.prototype.transDict = function(code, value) {
    if (!value && value !== 0) {
      return '--'
    }
    let dictList = AppStore.sessionState.query
    if (dictList.length > 0) {
      for (let i = 0; i < dictList.length; i++) {
        if (dictList[i].code === code && dictList[i].value.toString() === value.toString()) {
          return dictList[i].name
        }
      }
    }
    return '--'
  }
  // 获取省市区名字
  Vue.prototype.getDistrictName = function(id) {
    let districts = AppStore.sessionState.districts ? AppStore.sessionState.districts : []
    var name = ''
    try {
      id = JSON.parse(id)
      id.forEach((oneId) => {
        districts.forEach((item) => {
          if (item.id === oneId) {
            name += item.district + '-'
          }
        })
      })
      name = name.slice(0, name.length - 1)
    } catch {
      districts.forEach((item) => {
        if (item.id === id) {
          name = item.district
        }
      })
    }
    return name
  }
  // 获取字典列表
  Vue.prototype.getCodeList = function(code) {
    let dictList = AppStore.sessionState.query
    let arr = []
    if (dictList.length > 0) {
      dictList.forEach((item) => {
        if (item.code === code) {
          arr.push(item)
        }
      })
    }
    return arr
  }
  Vue.prototype.getAssetTypeName = function(type) {
    // 获取生产要素类型名称
    let typeList = AppStore.sessionState.typeList
    let name = '--'
    if (type == '其他') {
      name = '其他'
    } else {
      typeList.forEach((item) => {
        if (item.id == type) {
          name = item.assetName
        }
      })
    }
    return name
  }
  Vue.prototype.$dealImgArr = (imgs, type) => {
    // type===arr则返回数组
    let imgArr = []
    try {
      imgArr = JSON.parse(imgs)
    } catch (e) {
      let arr = []
      let cover = imgs ? imgs.split(',') : []
      cover.forEach((item) => {
        arr.push({ url: item })
      })
      imgArr = arr
    }
    return type === 'arr' ? imgArr : imgArr?.length ? imgArr[0].url : null
  }
}
