<template>
  <el-input :value="showValue" :placeholder="placeholder" @input="input" @change="change" v-bind="$attrs"> </el-input>
</template>
<script>
import { moneyWanToYuan, moneyYuanToWan } from '@/core/math2'
export default {
  // data() {
  //   return {
  //     value: '',
  //   }
  // },
  props: {
    value: {
      default: '',
    },
        placeholder: {
      default: '请输入',
    },
    type: {
      default: 'default' // 类型为default 时，填写什么返回什么，当类型为wan 的时候，value默认返回元，但是展示为万，
    }
  },
  computed: {
    showValue () {
      if (!this.value) return this.value
      const valArr = this.value.toString().split('.')
      if (valArr.length !== 1 && !valArr[1]) return this.value // 输入的时候  判断是‘23.’这种情况，先让输入。
      if (this.type === 'wan') return moneyYuanToWan(this.value)
      return this.value
    }

  },
  // watch: {
  //   value(val) {
  //     val = parseFloat(val)
  //     if (!/(?:^[1-9]([0-9]{0,4})?(?:\.[0-9]{1,4})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9]{0,3})?$)/.test(val) && val) {
  //       this.value = this.value.slice(0, this.value.length - 1)
  //     }
  //     this.$emit('input', parseFloat(this.value) ? this.value : '')
  //   },
  // },
  methods: {
    // 输入的时候校验。发现不对  直接清空
    input(val) {
      if (val === '.') return this.$emit('input', '')
      const valArr = val.split('.')
      if (valArr.length > 2) return this.$emit('input', '')
      if (valArr.length !== 1 && !valArr[1]) return this.$emit('input', val) // 输入的时候  判断是‘23.’这种情况，先让输入。
      this.change(val)
    },
    // 输入完成后，再确认一遍，为了过滤‘23.’这种情况
    change(val) {
      if (!/(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,4})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9]{0,3})?$)/.test(val)) {
        return this.$emit('input', '') // 判断非数字  直接清理
      }
      if (!/(?:^[1-9]([0-9]{0,4})?(?:\.[0-9]{1,4})?$)|(?:^(?:0)$)|(?:^[0-9]\.[0-9](?:[0-9]{0,3})?$)/.test(val)) {
        const newvalue = val.slice(0, val.length - 1) // 这句是令彰写的逻辑，我也不懂为啥。判断几位数字的吧。
        if (this.type === 'wan') return this.$emit('input', moneyWanToYuan(newvalue).toString())
        return this.$emit('input', newvalue)
      }
      if (this.type === 'wan') return this.$emit('input', moneyWanToYuan(val).toString())
      this.$emit('input', val)
    },
  },
}
</script>
