var Storage = /** @class */ (function () {
  function Storage(namespace, storage) {
    this.storage = window.localStorage
    this.namespace = 'root'
    if (storage) {
      this.storage = storage
    }
    if (typeof namespace === 'string') {
      this.namespace = namespace
    } else {
      this.namespace = namespace.join(':')
    }
  }
  // 清除指定模块的缓存
  Storage.ClearLocalStorageByNps = function (namespace) {
    Storage.ClearNsp(window.localStorage, namespace)
  }
  // 清除指定模块的缓存
  Storage.ClearSessionStorageByNps = function (namespace) {
    Storage.ClearNsp(window.sessionStorage, namespace)
  }
  Storage.ClearNsp = function (storage, namespace) {
    var nsp = ''
    if (typeof namespace === 'string') {
      nsp = namespace
    } else {
      nsp = namespace.join(':')
    }
    nsp = '^' + nsp
    var vals = storage.valueOf()
    var keys = Object.keys(vals)
    keys.forEach(function (key) {
      if (key.match(nsp)) {
        storage.removeItem(key)
      }
    })
  }
  // 清除当前模块的数据
  Storage.prototype.clear = function () {
    Storage.ClearNsp(this.storage, this.namespace)
  }
  Storage.prototype.remove = function (key) {
    var k = this.realKey(key)
    this.storage.removeItem(k)
  }
  Storage.prototype.set = function (val) {
    var k = this.namespace
    if (val === undefined) {
      this.remove(k)
      return
    }
    this.storage.setItem(k, this.serialize(val))
    return
  }
  Storage.prototype.get = function () {
    var k = this.namespace
    return this.deserialize(this.storage.getItem(k))
  }
  Storage.prototype.serialize = function (value) {
    return JSON.stringify(value)
  }
  Storage.prototype.deserialize = function (value) {
    if (typeof value !== 'string') {
      return undefined
    }
    try {
      return JSON.parse(value)
    } catch (e) {
      return value || undefined
    }
  }
  Storage.prototype.realKey = function (key) {
    return this.namespace + ':' + key
  }
  return Storage
})()
export default Storage
