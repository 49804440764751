import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {},
  getters: {
    recruitScrollY: (state) => state.recruitScrollY,
  },
  state: {
    loading: false,
    regLoading: false,
    payLoading: false,
    showAgreement: false,
    showPolicy: false,
    realNameShow: false,
    recruitScrollY: 0,
    unreadNum: 0,
  },
  mutations: {
    saveUnreadNum(state, value) {
      state.unreadNum = value
    },
    changeRecruitScrollY(state, recruitScrollY) {
      state.recruitScrollY = recruitScrollY
    },
    hideLoading(state) {
      state.loading = false
    },
    showLoading(state) {
      state.loading = true
    },
    hideAgreement(state) {
      state.showAgreement = false
    },
    showAgreement(state) {
      state.showAgreement = true
    },
    hidePolicy(state) {
      state.showPolicy = false
    },
    showPolicy(state) {
      state.showPolicy = true
    },
    hideRealName(state) {
      state.realNameShow = false
    },
    showRealName(state) {
      state.realNameShow = true
    },
  },
  actions: {},
})
export default store
