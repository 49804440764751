import hrt from './axios'
let hrtProxy = hrt('/asset-platform-api')
// 获取手机验证码
export const getCodeByMobile = (params) => {
  return hrtProxy.post(`/app/auth/getCodeByMobile`, params)
}
// 手机加验证码登录 /platform-api/app/auth/loginByMobile
export const loginByMobile = (params) => {
  return hrtProxy.post(`/app/auth/loginByMobile`, params).then((res) => res.data)
}
// 手机加密码登录 /platform-api/app/auth/login
export const loginByPassword = (params) => {
  return hrtProxy
    .post(`/app/auth/login`, params)
    .then((res) => res.data)
    .catch((res) => res.data)
}
// 修改密码 changePassword
export const changePassword = (params) => {
  return hrtProxy.post(`/app/auth/changePassword`, params).then((res) => res.data)
}
// 注册 /platform-api/app/auth/register
export const register = (data) => {
  return hrtProxy.post(`/app/auth/register`, data).then((res) => res.data)
}
// 获取用户信息  /asset-platform-api/app/auth/getUerInfo
export const getUerInfo = (data) => {
  return hrtProxy.post(`/app/auth/getUerInfo`, data).then((res) => res.data)
}
// 消息列表
export const getMessageList = (params) => {
  return hrtProxy.get(`/app/message/list`, { params }).then((res) => res.data)
}
// 消息详情
export const getMessageInfo = (params) => {
  return hrtProxy.get(`/app/message/info/${params.id}`).then((res) => res.data)
}
// 获取总的 未读消息 app/message/getUnreadMessageCount
export const getUnreadMessageCount = () => {
  return hrtProxy.get(`/app/message/getUnreadMessageCount`).then((res) => res.data)
}
// 退出 /asset-platform-api/app/auth/logout
export const logout = (data) => {
  return hrtProxy.post(`/app/auth/logout`, data).then((res) => res.data)
}
