<template>
  <div>
    <el-select v-model="selectValue" autocomplete clearable :disabled="disabled" :placeholder="placeholder" filterable>
      <el-option v-for="(item, index) in options" :key="index" :label="item.name" :value="item.value"> </el-option>
    </el-select>
  </div>
</template>

<script>
import { getQueryAllByCode } from '@/api/assets'
export default {
  name: 'el-dict',
  componentName: 'ElDict',
  data() {
    return {
      placeholder: '请选择',
      options: [],
      selectValue: '',
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    // 导入的url地址
    code: {
      type: String,
    },
    // 接受外部v-model传入的值，必须使用value
    value: {
      type: [String, Number],
      default: '',
    },
    filterList: {
      type: Array,
      default: function() {
        return []
      },
    },
  },
  watch: {
    // 判断下拉框的值是否有改变
    selectValue(val, oldVal) {
      if (val !== oldVal) {
        this.$emit('input', this.selectValue)
      }
    },
    value() {
      this.initData()
    },
  },
  methods: {
    initData() {
      if (typeof this.value === 'number') {
        this.selectValue = this.value.toString()
      } else {
        this.selectValue = this.value
      }
    },
  },
  mounted() {
    this.initData()
    // 远程请求回来的数据
    getQueryAllByCode({ code: this.code, status: 1 }).then((res) => {
      this.options = res.data
    })
  },
}
</script>
