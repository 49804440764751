<template>
  <el-upload
    :disabled="disabled"
    :action="url"
    :headers="headers"
    :list-type="type"
    :before-upload="beforeUploadHandle"
    :on-success="successHandle"
    :on-remove="handleRemove"
    :on-exceed="handExceed"
    :on-preview="handlePreview"
    :file-list="saveFileList"
    :limit="limit"
    ref="upload"
    :class="{ 'hide-upload': disabled || limit <= fileListNew.length }"
  >
    <el-button size="small" type="primary" v-if="type === 'text'">点击上传</el-button>
    <i class="el-icon-plus" v-else></i>
    <span v-if="prompt" style="color: #999;margin-left: 10px;">{{ prompt }}</span>
    <div slot="tip" class="el-upload__tip">{{ uploadTip }}</div>
  </el-upload>
</template>
<script>
let loading = null
export default {
  data() {
    return {
      // url: '/asset-platform-api/app/upload/upload?isPrivate=true',
      saveFileList: [],
      fileListNew: [],
      isInit: false,
      headers: { token: this.$AppStore.localState.info ? this.$AppStore.localState.info.token : '' },
    }
  },
  props: {
    value: [String, Array],
    disabled: {
      type: Boolean,
      default: false,
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
    uploadTip: [String],
    prompt: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 99,
    },
    maxSize: {
      type: Number,
      default: 10 * 1024 * 1024,
    },
    type: {
      type: String,
      default: 'picture-card',
    },
    FileType: {
      type: Array,
      default: function() {
        return ['image/jpg', 'image/jpeg', 'image/png']
      },
    },
  },
  mounted() {
    // this.isInit = false
    this.$nextTick(() => {
      if (this.fileListNew) {
        this.$refs.upload.clearFiles()
        this.saveFileList = []
      }
      this.initData()
    })
  },
  watch: {
    value: function() {
      let saveFileListNew = []
      if (this.ValueIsArray) {
        saveFileListNew = this.value
      } else {
        if (this.value) {
          try {
            saveFileListNew = JSON.parse(this.value)
          } catch (e) {
            const saveFileList = saveFileListNew.map((item) => item.url)
            this.value.split(',').forEach((item, index) => {
              if (saveFileList.indexOf(item) === -1) {
                saveFileListNew.push({ name: `附件${index + 1}`, url: item })
              }
            })
          }
        } else {
          saveFileListNew = []
        }
      }
      if (saveFileListNew.length === this.fileListNew.length) return
      this.initData()
    },
  },
  computed: {
    ValueIsArray() {
      if (!Array.isArray) {
        Array.isArray = function(arg) {
          return Object.prototype.toString.call(arg) === '[object Array]'
        }
      }
      return Array.isArray(this.value)
    },
    url() {
      return this.isPrivate ? '/asset-platform-api/app/upload/upload?isPrivate=true' : '/asset-platform-api/app/upload/upload'
    },
  },
  methods: {
    initData() {
      if (this.ValueIsArray) {
        this.saveFileList = this.value
        this.saveFileList.forEach((item) => {
          let url = item.url
          if (url.indexOf('app/upload/downloadFile') > -1 && url.indexOf('?') < 0) {
            item.url = url + `?token=${this.$AppStore.localState.info.token}&type=api`
          }
        })
        return
      }
      if (this.value) {
        try {
          this.saveFileList = JSON.parse(this.value)
        } catch (e) {
          const saveFileList = this.saveFileList.map((item) => item.url)
          this.value.split(',').forEach((item, index) => {
            if (saveFileList.indexOf(item) === -1) {
              this.saveFileList.push({ name: `附件${index + 1}`, url: item })
            }
            // return { nama: '', url: item }
          })
        }
      } else {
        this.saveFileList = []
      }
      this.saveFileList.forEach((item) => {
        let url = item.url
        if (url.indexOf('app/upload/downloadFile') > -1 && url.indexOf('?') < 0) {
          item.url = url + `?token=${this.$AppStore.localState.info.token}&type=api`
        }
      })
      this.fileListNew = this.saveFileList
    },
    beforeUploadHandle(file) {
      if (this.FileType.length && this.FileType.indexOf(file.type) === -1) {
        this.$message.error('文件格式不支持！')
        return false
      }
      const isLt2M = file.size / this.maxSize < 1
      if (!isLt2M) {
        let limit = this.maxSize / 1024 / 1024
        this.$message.error('文件太大, 不得超过' + limit + 'M')
        return false
      }
      loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
    },
    // 上传成功
    successHandle(response, file, fileList) {
      let url = response.url
      if (url.indexOf('app/upload/downloadFile') > -1 && url.indexOf('?') < 0) {
        response.url = url + `?token=${this.$AppStore.localState.info.token}&type=api`
      }
      if (loading) loading.close()
      // this.isInit = true
      if (response && response.code !== 0) {
        return this.$message.error(response.msg)
      }
      // 不懂为什么 需要加这一串 多文件上传才会触发三次
      let oldfileListNew = JSON.parse(JSON.stringify(this.fileListNew))
      const fileListNew2 = fileList.map((item, index) => {
        if (index + 1 === fileList.length) item.url = response.url
        return { name: item.name, url: item.url }
      })
      this.fileListNew = fileListNew2
      this.fileListNew = []
      oldfileListNew.push({ name: file.name, url: response.url })
      this.fileListNew = oldfileListNew
      let fileListNew = this.fileListNew
      // 上面一串
      fileListNew.forEach((item) => {
        let url = item.url
        if (url.indexOf('?') > -1) {
          item.url = url.split('?')[0]
        }
      })
      if (this.ValueIsArray) return this.$emit('input', fileListNew)
      this.$emit('input', JSON.stringify(fileListNew))
    },
    // 移除文件
    handleRemove(file, fileList) {
      // this.isInit = true
      const fileListNew = fileList.map((item) => {
        return { name: item.name, url: item.url }
      })
      this.fileListNew = fileListNew
      if (this.ValueIsArray) return this.$emit('input', fileList)
      this.$emit('input', JSON.stringify(fileListNew))
    },
    handExceed() {
      // console.log(fileList)
      this.$message.error('超出上传上限，请删除现有附件后再上传')
    },
    handlePreview(file) {
      let url = file.url
      if (url.indexOf('app/upload/downloadFile') > -1 && url.indexOf('?') < 0) {
        file.url = url + `?token=${this.$AppStore.localState.info.token}&type=api`
      }
      if (['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.gif', '.GIF'].some((item) => file.url.indexOf(item) !== -1)) {
        // this.downloadIamge(file.url, file.name)
        window.open(file.url)
      } else if (['.pdf'].some((item) => file.url.indexOf(item) !== -1)) {
        window.open(file.url)
      } else if (['.txt'].some((item) => file.url.indexOf(item) !== -1)) {
        this.downloadTxt(file.url, file.name)
      } else {
        // this.downloadFile(file.url)
        this.download(file.url, file.name)
      }
    },
    downloadFile(url) {
      const iframe = document.createElement('iframe')
      iframe.src = url
      iframe.style.display = 'none'
      document.body.appendChild(iframe)
    },
    download(url, filename) {
      this.getBlob(url, (blob) => {
        this.saveAs(blob, filename)
      })
    },
    getBlob(url, cb) {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = function() {
        if (xhr.status === 200) {
          cb(xhr.response)
        }
      }
      xhr.send()
    },
    saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename)
      } else {
        var link = document.createElement('a')
        var body = document.querySelector('body')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        // fix Firefox
        link.style.display = 'none'
        body.appendChild(link)
        link.click()

        body.removeChild(link)

        window.URL.revokeObjectURL(link.href)
      }
    },
    downloadTxt(url, name) {
      // txt等格式
      // 判断地址栏是否有.txt，有就是txt文件
      var xmlHttp = null
      if (window.ActiveXObject) {
        // IE6, IE5 浏览器执行代码
        xmlHttp = new window.ActiveXObject('Microsoft.XMLHTTP')
      } else if (window.XMLHttpRequest) {
        // IE7+, Firefox, Chrome, Opera, Safari 浏览器执行代码
        xmlHttp = new XMLHttpRequest()
      }
      // 2.如果实例化成功，就调用open（）方法：
      if (xmlHttp != null) {
        xmlHttp.open('get', url, true)
        xmlHttp.send()
        xmlHttp.onreadystatechange = doResult // 设置回调函数
      }
      function doResult() {
        if (xmlHttp.readyState === 4) {
          // 4表示执行完成
          if (xmlHttp.status === 200) {
            // 200表示执行成功
            // 创建隐藏的可下载链接
            var aLink = document.createElement('a')
            aLink.download = name // 设置a标签的下载名字
            aLink.style.display = 'none'
            // 字符内容转变成blob地址
            var blob = new Blob([xmlHttp.responseText])
            aLink.href = URL.createObjectURL(blob)
            // 触发点击
            document.body.appendChild(aLink)
            aLink.click()
            // 然后移除
            document.body.removeChild(aLink)
          }
        }
      }
    },
    downloadIamge(imgsrc, name) {
      var image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function() {
        var canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL('image/png')
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = name || 'photo'
        a.href = url
        a.dispatchEvent(event)
      }
      image.src = imgsrc
    },
  },
}
</script>
<style lang="scss">
.hide-upload .el-upload {
  display: none;
}
</style>
