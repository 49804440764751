import hrt from './axios'
let hrtProxy = hrt('/asset-platform-api')
// 获取所有字典
export const getQueryAll = (params) => {
  return hrtProxy.get(`/app/dist/queryAll`, { params }).then((res) => res.data)
}
// 根据code查询字典 /property-api/app/dist/queryByCode
export const getQueryAllByCode = (params) => {
  return hrtProxy.get(`/app/dist/queryAll`, { params }).then((res) => res.data)
}
// 获取所有要素类型 /property-api/app/assettypesign/queryAll
export const getAssetType = (params) => {
  return hrtProxy.get(`/app/assettypesign/queryAll`, { params }).then((res) => res.data)
}
// 新增要素 /property-api/app/asset/save
export const saveAsset = (data) => {
  return hrtProxy.post(`/app/asset/save`, data).then((res) => res.data)
}
// 修改要素 /property-api/app/asset/update
export const editAsset = (data) => {
  return hrtProxy.put(`/app/asset/update`, data).then((res) => res.data)
}
// 提交审核 app/asset/submitToAudit
export const submitToAudit = (data) => {
  return hrtProxy.post(`/app/asset/submitToAudit`, data).then((res) => res.data)
}
// 获取模板表单详情
export const queryTempletInfo = (params) => {
  return hrtProxy.get(`/app/form/getFormFieldList`, { params }).then((res) => res.data)
}
// 获取我的要素 /property-api/app/asset/myList
export const getMyAssetList = (params) => {
  return hrtProxy.get(`/app/asset/myList`, { params }).then((res) => res.data)
}
// 获取生产要素详情 /property-api/app/asset/info/{id}
export const getAssetDetail = (params) => {
  return hrtProxy.get(`/app/asset/info/${params.id}`).then((res) => res.data)
}
// 根据生产要素类型获取项目模板 /property-api/app/project/getProjectFormTemplate/{id}
export const getProjectFormTemplate = (params) => {
  return hrtProxy.get(`/app/project/getProjectFormTemplate/${params.id}`).then((res) => res.data)
}
// 获取估值报告
export const getAssetValue = (params) => {
  return hrtProxy.get(`/app/asset/assetValue/${params.id}`).then((res) => res.data)
}
// 新增金融订单 /asset-platform-api/app/productorder/save
export const saveProductorder = (data) => {
  return hrtProxy.post(`/app/productorder/save`, data).then((res) => res.data)
}
// 编辑金融订单 /asset-platform-api/app/productorder/update
export const editProductorder = (data) => {
  return hrtProxy.post(`/app/productorder/update`, data).then((res) => res.data)
}
// 重新申请融资 updateProductOrderForReApply
export const updateProductOrderForReApply = (data) => {
  return hrtProxy.post(`/app/productorder/updateProductOrderForReApply`, data).then((res) => res.data)
}
// 我的融资列表app/productorder/getMyFinancialProductPage
export const getMyFinancialProductPage = (params) => {
  return hrtProxy.get(`/app/productorder/getMyFinancialProductPage`, { params }).then((res) => res.data)
}
// // 我的融资详情app/productorder/info
export const getProductorderInfo = (params) => {
  return hrtProxy.get(`/app/productorder/info/${params.id}`).then((res) => res.data)
}
// 获取发起融资 银行列表 app/product/getFinancialBankName
export const getFinancialBankName = (params) => {
  return hrtProxy.get(`/app/product/getFinancialBankName`, { params }).then((res) => res.data)
}
// 获取要素融资列表 筛序条件发布机构列表
export const getFinancialListBankName = (param) => {
  let params = {
    ...param,
    orgNo: '02',
  }
  return hrtProxy.get(`/app/org/queryListByOrgNo`, { params }).then((res) => res.data)
}
// 意向金融列表 app/product/list
export const getproductList = (params) => {
  return hrtProxy.get(`/app/product/list`, { params }).then((res) => res.data)
}
// 金融服务推荐列表
export const getRecommendProductList = (params) => {
  return hrtProxy.get(`/app/product/randList`, { params }).then((res) => res.data)
}
// 意向金融详情 app/product/list
export const getproductInfo = (params) => {
  return hrtProxy.get(`/app/product/info/${params.productId}`).then((res) => res.data)
}
// 获取融资已备案详情 app/productorder/getFinancialProductOrderInfo
export const getFinancialProductOrderInfo = (params) => {
  return hrtProxy.get(`/app/productorder/getFinancialProductOrderInfo`, { params }).then((res) => res.data)
}
// 判断是否已经融资过 或 流转过 checkAssetIsRun
export const checkAssetIsRun = (params) => {
  return hrtProxy.get(`/app/asset/checkAssetIsRun`, { params }).then((res) => res.data)
}
// 获取金融服务详情选择生产要素--列表 /platform-api/app/asset/getAssetAdaptList/{id}
export const getAssetAdaptList = (params) => {
  return hrtProxy.get(`/app/asset/getAssetAdaptList/${params.id}`).then((res) => res.data)
}
// 获取图片数据  asset/asset/iocr/{url}
export const getIocr = (data) => {
  return hrtProxy.post(`/app/asset/iocr`, data).then((res) => res.data)
}
// 求助 /asset-platform-api/app/askhelp/save
export const askhelp = (data) => {
  return hrtProxy.post(`/app/askhelp/save`, data).then((res) => res.data)
}
// 申请评估 /asset-platform-api/app/assessmentvalue/save/{assetId}
export const saveAssessmentvalue = (data) => {
  return hrtProxy.post(`/app/assessmentvalue/save/${data.assetId}`, data).then((res) => res.data)
}
// 获取评估列表  /asset-platform-api/app/assessmentvalue/myList
export const getAssessmentvalueList = (params) => {
  return hrtProxy.get(`/app/assessmentvalue/myList`, { params }).then((res) => res.data)
}
// 获取评估详情 /asset-platform-api/app/assessmentvalue/info/{id}
export const getAssessmentvalueInfo = (params) => {
  return hrtProxy.get(`/app/assessmentvalue/info/${params.id}`).then((res) => res.data)
}
// 还款记录 app/repayment/list
export const getRepaymentList = (params) => {
  return hrtProxy.get(`/app/repayment/record/list/${params.financialOrderId}`, { params }).then((res) => res.data)
}
// 还款详情 /asset-platform-api/app/repayment/record/list/{financialOrderId}
export const getRepaymentInfo = (params) => {
  return hrtProxy.get(`/app/repayment/record/info/${params.financialOrderId}`).then((res) => res.data)
}
// 评价 assessmentcomment
export const saveAssessmentcomment = (data) => {
  return hrtProxy.post(`/app/assessmentComment/save`, data).then((res) => res.data)
}
// 评价详情assessmentcomment
export const getAssessmentcommentInfo = (params) => {
  return hrtProxy.get(`/app/assessmentComment/info/${params.id}`).then((res) => res.data)
}
// 登记 要素和融资 app/productorder/saveAssetAndProdorder
export const saveAssetAndProdorder = (data) => {
  return hrtProxy.post(`/app/productorder/saveAssetAndProdorder`, data).then((res) => res.data)
}
// 获取融资详情的公告列表 app/news/getNewListByFinancialprodId
export const getNewListByFinancialprodId = (params) => {
  return hrtProxy.get(`/app/news/getNewListByFinancialprodId`, { params }).then((res) => res.data)
}