<template>
  <div>
    <el-select @change="change" v-model="selectValue" autocomplete clearable :disabled="disabled" :placeholder="placeholder" filterable>
      <el-option v-for="item in options" :key="item.key" :label="item.assetName" :value="item.id"> </el-option>
      <el-option v-if="isAsk" label="其他" value="其他"> </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'el-selectAsset',
  componentName: 'ElSelectAsset',
  data() {
    return {
      placeholder: '请选择',
      options: [],
      selectValue: '',
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isAsk: {
      type: Boolean,
      default: false,
    },
    // 接受外部v-model传入的值，必须使用value
    value: {
      type: [String, Number],
      default: '',
    },
    useType: {
      type: [String, Number],
      default: '',
    },
    assetTypes: String,
  },
  watch: {
    // 判断下拉框的值是否有改变
    selectValue(val, oldVal) {
      if (val !== oldVal) {
        this.$emit('input', this.selectValue)
      }
    },
    value() {
      this.initData()
    },
    useType() {
      this.selectAssets()
    },
    assetTypes(val) {
      if (val) {
        this.selectAssetstwo()
      }
    },
  },
  methods: {
    change(val) {
      let name = ''
      this.options.forEach((item) => {
        if (item.id === val) {
          name = item.assetName
          if (item.assetName === '水域滩涂养殖权') this.$emit('change', true)
          else this.$emit('change', false)
        }
      })
      if (!name) this.$emit('change', false)
    },
    initData() {
      if (typeof this.value === 'number') {
        this.selectValue = this.value.toString()
      } else {
        this.selectValue = this.value
      }
    },
    // 筛选对应要素类型
    selectAssets() {
      if (this.assetTypes) return
      this.options = []
      let arr = this.$AppStore.sessionState.typeList || []
      arr.forEach((item) => {
        if (item.useType === parseInt(this.useType)) {
          this.options.push(item)
        }
      })
    },
    // 过滤要素类型
    selectAssetstwo() {
      this.options = []
      let assetTypes = this.assetTypes.split(',')
      let arr = this.$AppStore.sessionState.typeList || []
      arr.forEach((item) => {
        assetTypes.forEach((item2) => {
          if (item.id === item2) {
            this.options.push(item)
          }
        })
      })
    },
  },
  mounted() {
    this.initData()
    // 远程请求回来的数据
    this.options = this.$AppStore.sessionState.typeList || []
    if (this.useType) this.selectAssets()
    if (this.assetTypes) this.selectAssetstwo()
  },
}
</script>
