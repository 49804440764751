<template>
  <div id="app">
    <regist v-if="$store.state.regLoading"></regist>
    <login v-if="$store.state.loading"></login>
    <realName></realName>
    <agreeAndPolicy v-if="$store.state.showAgreement"></agreeAndPolicy>
    <agreeAndPolicy :type="2" v-if="$store.state.showPolicy"></agreeAndPolicy>
    <router-view />
  </div>
</template>
<script>
import login from '@/components/login'
import regist from '@/components/regist'
import realName from '@/components/realName'
import agreeAndPolicy from '@/views/components/agreeAndPolicy'
export default {
  name: 'App',
  components: { login, regist, agreeAndPolicy, realName },
  mounted() {
    // console.log(this.$store.state.loading)
  },
}
</script>

<style>
@import '~@/assets/styles/index.scss'; /*引入公共样式*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
legend,
button form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
  border: 0;
}
/* 清除列表样式 */
ol,
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
#app {
  font-family: 'Microsoft YaHei', 'Microsoft Sans Serif', tahoma, arial, 'Hiragino Sans GB', '\5b8b\4f53', sans-serif;
  font-size: 14px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  background: #f5f5f5;
  color: #333;
}
</style>
