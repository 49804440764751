<template>
  <div>
    <el-input placeholder="请输入验证码" v-model="code" @input="oninput" :prefix-icon="showIcon ? 'el-icon-lock' : ''">
      <el-button slot="append" :disabled="isSend" @click="getCode" type="primary">{{ tips }}</el-button>
    </el-input>
    <Slider v-if="showSlider" @close="closeCaptcha" @success="validSucess()" :log="true"></Slider>
  </div>
</template>
<script>
import { getCodeByMobile } from '@/api/user'
import Slider from './slider.vue'
export default {
  props: {
    mobile: {
      type: String,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Boolean,
    },
    showSlide: {
      type: Boolean,
    },
  },
  components: {
    Slider,
  },
  data() {
    return {
      code: '',
      isSend: false,
      tips: '获取验证码',
      time: '',
      seconds: 60,
      showSlider: false,
    }
  },
  methods: {
    closeCaptcha() {
      this.showSlider = false
    },
    async getCode() {
      if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.mobile)) {
        this.$message.error('请输入11位数的手机号')
        return false
      }
      if (!this.status && this.showSlide) {
        this.$message.error('请先完成滑块验证')
        return false
      }
      this.showSlider = true
    },
    async validSucess() {
      this.showSlider = false
      let params = {
        mobile: this.mobile,
      }
      await getCodeByMobile(params)
      this.$message({
        message: '验证码发送成功',
        type: 'success',
      })
      this.$emit('successFun')
      this.cutTime()
    },
    // 倒计时
    cutTime() {
      this.isSend = true
      this.time = setInterval(() => {
        this.seconds--
        this.tips = `已发送(${this.seconds})`
        if (this.seconds === 0) {
          this.tips = '重新获取'
          this.isSend = false
          this.seconds = 60
          clearInterval(this.time)
        }
      }, 1000)
    },
    oninput() {
      this.$emit('input', this.code)
    },
    reset() {
      clearInterval(this.time)
      Object.assign(this.$data, this.$options.data())
    },
  },
}
</script>
<style lang="scss" scoped>
.el-button--primary {
  color: #fff !important;
  background-color: #409eff !important;
  border-color: #409eff !important;
}
</style>
