import Vue from 'vue'
import Storage from './storage'
var data = {}
/**
 * 将数据渲染至vue示例内。触发双向绑定。
 */
var vm = new Vue({
  name: 'Store',
  data: { data: data },
  render: function (h) {
    return h('div')
  },
})
/**
 * 开发环境下，将vue示例渲染至dom。
 * 用于vue-tools调试
 */
if (process.env.NODE_ENV === 'development') {
  var el = document.createElement('div')
  document.body.insertBefore(el, document.body.firstElementChild)
  vm.$mount(el)
}
var Data = /** @class */ (function () {
  function Data() {
    this.storages = {
      local: null,
      session: null,
    }
    // 模块名称，【必须】不能重复
    // 格式为 AAA:BBB:CCC ，指当前模块属于 AAA.BBB 模块，名为 CCC
    this.name = 'ROOT'
  }
  // 清除当前模块的缓存
  Data.prototype.clear = function (type) {
    if (type === void 0) {
      type = 'all'
    }
    var local = this.storages.local
    var session = this.storages.session
    if (session && type !== 'local') {
      session.clear()
    }
    if (local && type !== 'session') {
      local.clear()
    }
  }
  Data.prototype.initilization = function () {
    var module = this
    vm.$set(vm.data, module.name + '-state', this.state)
    if (module.localState) {
      this.createWatchState('local')
    }
    if (module.sessionState) {
      this.createWatchState('session')
    }
  }
  Data.prototype.createWatchState = function (type) {
    var module = this
    var State = type === 'session' ? module.sessionState : module.localState
    var storage = new Storage(module.name, type === 'session' ? sessionStorage : localStorage)
    module.storages[type] = storage
    var state = storage.get()
    if (state) {
      var keys = Object.keys(state)
      keys.forEach(function (key) {
        State[key] = state[key]
      })
    }
    // 设置当前模块数据到数据中心内。
    var key = module.name + '-' + type
    vm.$set(vm.data, key, State)
    vm.$watch(
      function () {
        return this.data[key]
      },
      function (val) {
        storage.set(val)
      },
      {
        deep: true,
      }
    )
  }
  return Data
})()
export default Data
