<template>
  <el-dialog title="注册" :visible.sync="$store.state.regLoading" width="30%">
    <el-form label-width="0" :model="form" :rules="rules" ref="ruleForm">
      <el-form-item prop="mobile">
        <el-input v-model="form.mobile" placeholder="请输入手机号码" prefix-icon="el-icon-mobile-phone"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model="form.password" placeholder="请输入密码" prefix-icon="el-icon-lock" maxlength="20" show-password></el-input>
      </el-form-item>
      <el-form-item prop="confirmPassword">
        <el-input type="password" v-model="form.confirmPassword" placeholder="请再次输入密码" prefix-icon="el-icon-lock" maxlength="20" show-password></el-input>
      </el-form-item>
      <el-form-item prop="userName">
        <el-input v-model="form.userName" placeholder="请填写您本人的真实姓名" prefix-icon="el-icon-user"></el-input>
      </el-form-item>
      <el-form-item prop="idNo">
        <el-input v-model="form.idNo" placeholder="请填写您本人的身份证号" prefix-icon="el-icon-postcard"></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <getCodeInput v-model="form.code" :mobile="form.mobile" :showIcon="true"></getCodeInput>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmit">注 册</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { register } from '@/api/user'
import getCodeInput from '@/views/components/getCodeInput.vue'
import { Encrypt } from '@/utils/crypto'
export default {
  components: { getCodeInput },
  data() {
    return {
      form: {
        mobile: '',
        password: '',
        confirmPassword: '',
        userName: '',
        idNo: '',
        code: '',
      },
      rules: {
        mobile: [
          {
            required: true,
            message: '请输入手机号码',
            // blur和change事件触发检验
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            transform(value) {
              return String(value)
            },
            message: '请输入11位数手机号码',
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: ['blur', 'change'],
          },
          {
            pattern: /.{8,20}$/,
            message: '请输入8-20位字符的密码',
            trigger: ['blur', 'change'],
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: '请确认密码',
            trigger: ['blur', 'change'],
          },
        ],
        code: [
          {
            required: true,
            message: '请确认验证码',
            trigger: ['blur', 'change'],
          },
        ],
        userName: [
          {
            required: true,
            message: '请输入不超过7个字，只能纯汉字',
            // blur和change事件触发检验
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^(?:[\u4e00-\u9fa5·]{0,16})$/,
            message: '请输入不超过7个字，只能纯汉字',
            // blur和change事件触发检验
            trigger: ['blur', 'change'],
          },
        ],
        idNo: [
          {
            required: true,
            message: '请输入18个字符，最后位可大小写X“',
            // blur和change事件触发检验
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
            message: '请输入18个字符，最后位可大小写X“',
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  methods: {
    onSubmit() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          if (this.form.password !== this.form.confirmPassword) {
            this.$message({
              message: '两次输入的密码不一致',
              type: 'error',
              duration: 1500,
            })
            return
          }
          register({
            mobile: this.form.mobile,
            password: Encrypt(this.form.password),
            idNo: this.form.idNo,
            userName: this.form.userName,
            code: this.form.code,
            registerSource: 2,
          }).then((res) => {
            if (res.code == 0) {
              this.$store.state.regLoading = false
              this.$message({
                message: '注册成功',
                type: 'success',
                duration: 1500,
              })
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped></style>
