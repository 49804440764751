<template>
  <div class="mainer" @click="close">
    <div @click.stop="done()">
      <loginForm></loginForm>
    </div>
  </div>
</template>
<script>
import loginForm from '@/views/components/loginForm.vue'
export default {
  components: { loginForm },
  data() {
    return {
      ruleForm: {},
    }
  },
  methods: {
    done() {},
    close() {
      this.$store.commit('hideLoading')
    },
  },
}
</script>
<style lang="scss" scoped>
.mainer {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  background: rgba($color: #000000, $alpha: 0.3);
  .loginBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 540px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(94, 92, 92, 0.13);
    opacity: 1;
    border-radius: 10px;
    padding: 50px 60px 50px 60px;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 52px;
    }
    .serchBtn {
      background: #1c78ef;
      color: #fff;
      font-size: 18px;
    }
    /deep/ {
      .el-input__icon {
        font-size: 26px;
        color: #333;
      }
    }
    .mark {
      color: #666;
      font-size: 19px;
      margin-top: 46px;
      .link {
        color: #1c78ef;
        cursor: pointer;
      }
    }
    .loginBtn {
      height: 50px;
      line-height: 50px;
      color: #fff;
      font-size: 16px;
      text-align: center;
      background: #1c78ef;
      box-shadow: 0px 10px 20px rgba(255, 73, 47, 0.1);
      border-radius: 4px;
      margin-top: 32px;
      cursor: pointer;
    }
  }
}
</style>
