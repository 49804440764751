('use strict')
var __extends =
  (this && this.__extends) ||
  (function() {
    var extendStatics = function(d, b) {
      extendStatics =
        Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array &&
          function(d, b) {
            d.__proto__ = b
          }) ||
        function(d, b) {
          for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]
        }
      return extendStatics(d, b)
    }
    return function(d, b) {
      if (typeof b !== 'function' && b !== null) throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null')
      extendStatics(d, b)
      function __() {
        this.constructor = d
      }
      d.prototype = b === null ? Object.create(b) : ((__.prototype = b.prototype), new __())
    }
  })()
exports.__esModule = true
var data_1 = require('@/utils/data')
var Store = /** @class */ (function(_super) {
  __extends(Store, _super)
  function Store() {
    var _this = _super.call(this) || this
    // 临时状态
    _this.state = {
      val4: [],
    }
    // sessionState
    _this.sessionState = {
      typeList: [],
      query: [],
      assetTypes: [],
      loanAssetTypeSignId:'',
    }
    // 刷新后依旧留存的状态
    _this.localState = {
      text: '',
      info: null,
    }
    // 模块名称，【必须】不能重复
    // 格式为 AAA:BBB:CCC ，指当前模块属于 AAA.BBB 模块，名为 CCC
    _this.name = 'nongquan'
    _this.initilization()
    // if (!_this.localState.config.login.automatic) this.localState.info = null
    // if (!_this.localState.login.password || !_this.localState.login.username) this.localState.info = null
    return _this
  }
  // 一些方法。
  Store.prototype.SaveTokenInfo = function(val) {
    this.localState.info = val
  }
  return Store
})(data_1['default'])
exports['default'] = new Store()
